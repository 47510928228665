<template>
    <div class="page-wrapper page-home">
        <!-- Nav Heading Spacer -->
        <div class="header-spacer"></div>

        <!-- Outro -->
        <section class="intro site-max-width theme-rouge outro" v-if="pageIsLoaded">
            <h2 class="big-title intro-title" data-inview="fadeInUp" data-delay="400">
                {{ content.page.title }}
            </h2>
            <div class="bottom">
                <p class="regular-text" data-inview="fadeInUp" data-delay="500">
                    <span v-html="content.page.contenuWysiwyg"></span>
                </p>
            </div>
        </section>

        <!-- Events -->
        <EvenementsListing v-if="pageIsLoaded" />
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'
import EvenementsListing from '@/components/EvenementsListing'

export default {
    name: 'Politique',

    data() {
        return {
        }
    },

    components: {
        EvenementsListing,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
